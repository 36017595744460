<template>
  <div>
    <div class="heading">
      <div>
        <h4 class="heading-4 mb-1">
          {{ $t("carriersPage.title") }}
        </h4>
        <p class="body-4 mb-0">{{ $t("carriersPage.subtitle") }}</p>
      </div>
      <div class="btn-actions">
        <NbButton
          v-if="!isSuper"
          @click="showCreatCarrierModal"
          class="d-flex justify-content-start"
        >
          <NbIcon icon="plus" class="mr-2" />
          {{ $t("carriersPage.newCarrier") }}
        </NbButton>

        <NbButton
          v-if="!isSuper"
          @click="showImportCarrierModal"
          class="d-flex justify-content-start"
        >
          <NbIcon icon="plus" class="mr-2" />
          {{ $t("carriersPage.importCarrier") }}
        </NbButton>

        <NbButton variant="secondary" @click="goToDeliveryMethodsPage">
          <NbIcon icon="plus" class="mr-2" />
          {{ $t("carriersPage.addDeliveryMethod") }}
        </NbButton>
      </div>
    </div>

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" />
      <DatatableRoot
        :namespace="namespace"
        ref="carriersRef"
        clickable
        :columns="tableColumns"
        :filters="tableFilters"
        @count="total = $event"
        @rowClick="loadModalData($event.id)"
      >
        <template #edit="{ row }">
          <div>
            <NbButton
              variant="tertiary"
              icon="edit"
              @click="showEditCarrierModal(row)"
            >
              {{ $t("edit") }}
            </NbButton>
          </div>
        </template>
        <template #is_blocked="{ row }">
          <template v-if="!row.is_blocked">
            <NbBadge :text="$t('carriersPage.allowed')" type="success" />
          </template>
          <template v-else>
            <NbBadge :text="$t('carriersPage.blocked')" type="danger" />
          </template>
        </template>
        <template #group_name="{ row }">
          <template v-if="row.group_name == 'Admin'">
            <NbBadge :text="row.group_name" />
          </template>
          <template v-else>
            <NbBadge :text="row.group_name" type="warning" />
          </template>
        </template>
        <template #is_seller="{ row }">
          <IsCheckedIcon :checked="row.is_seller" />
        </template>
        <template v-if="isSuper" #platform_name="{ row }">
          <span>{{ row.platform_name }}</span>
        </template>
      </DatatableRoot>
    </section>

    <Modal v-model="modalImportCarrier">
      <ModalHeader>
        <ModalTitle>
          {{ $t("upload") }}
        </ModalTitle>
      </ModalHeader>

      <div>
        <div class="d-flex align-items-start gap-2">
          <NbIcon size="18" class="text-warning" />
          <p class="body-4">
            {{ $t("carriersPage.importFromJsonHelpText") }}
          </p>
        </div>

        <NbDropFile
          v-model="chosenFile"
          :accept="['application/json']"
          :label="$t('alertLogsPage.browseFilesUpload')"
        />
        <div v-if="errors.length > 0">
          <p
            class="text-center text-danger"
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error }}
          </p>
        </div>
      </div>

      <ModalFooter class="d-flex justify-content-end">
        <ModalClose class="mr-2" />
        <NbButton @click="sendFile()">
          {{ $t("upload") }}
        </NbButton>
      </ModalFooter>
    </Modal>

    <Modal v-model="isModalCarrierView" @close="resetQuery()" size="lg">
      <ModalCarrierView
        ref="modalCarrierViewRef"
        :itemId="currentItemId"
        @showEditCarrierModal="showEditCarrierModal($event)"
        @reloadCarriers="reloadCarriers"
      />
    </Modal>

    <Modal
      v-model="isModalCarrierOpen"
      :carrier.sync="carrier"
      @reloadCarrier="reloadCarriers"
      @close="resetQuery()"
      size="lg"
    >
      <ModalHeader>
        <NbPageTitle
          :title="
            selectedCarrierId
              ? $t('carriersPage.editCarrier')
              : $t('carriersPage.addCarrier')
          "
          :subTitle="!selectedCarrierId ? $t('usersPage.insertUserData') : ''"
        />

        <div class="mr-4">
          <NbButton
            v-if="selectedCarrierId"
            size="sm"
            class="mr-3"
            @click="downloadCarrierSettings()"
            :disabled="carrierSettingsDownloading"
          >
            {{ $t("carriersPage.downloadCarrierSettings") }}
          </NbButton>
        </div>
      </ModalHeader>
      <div>
        <NbCard
          id="fill"
          :title="$t('carriersPage.fillWithSellerData')"
          class="mb-4"
        >
          <template #body>
            <div class="d-flex align-items-end gap-2">
              <NbSelectInput
                :name="$t('carriersPage.sellers')"
                id="sellers"
                v-model="selectedSellerId"
                :options="allSellerOptions"
                class="flex-grow-1"
                :help-text="$t('carriersPage.fillWithSellerDataHelpText')"
              />
              <NbButton
                :disabled="!selectedSellerId"
                @click="fillSellerToCarrierData"
                class="mb-1"
              >
                {{ $t("fill") }}
              </NbButton>
            </div>
          </template>
        </NbCard>
        <NbCard
          id="geral"
          :title="$t('carriersPage.generalData')"
          :subTitle="$t('carriersPage.addCarrierDetails')"
        >
          <template #body>
            <div class="form-group grid-cols-3">
              <NbTextInput
                :name="$t('carriersPage.name')"
                id="name"
                :error="errors['name']"
                v-model="carrier.name"
              />
              <NbTextInput
                :name="$t('carriersPage.email')"
                id="email"
                :error="errors['email']"
                v-model="carrier.email"
              />
              <NbTextInput
                :name="$t('carriersPage.phone')"
                id="phone"
                :error="errors['phone']"
                v-model="carrier.phone"
                placeholder="+00 0 0000-0000"
                :masks="['+#*']"
                type="text"
              />
            </div>
            <div class="form-group grid-cols-3">
              <NbTextInput
                :name="$t('carriersPage.taxnumber')"
                id="tax_number"
                :error="errors['tax_number']"
                v-model="carrier.tax_number"
              />
              <NbTextInput
                :name="$t('carriersPage.legalName')"
                id="legal_name"
                :error="errors['legal_name']"
                v-model="carrier.legal_name"
              />
              <NbTextInput
                :name="$t('carriersPage.rfbCode')"
                id="rfb_code"
                :error="errors['rfb_code']"
                v-model="carrier.rfb_code"
                :help-text="$t('carriersPage.rfbCodeHelpText')"
              />
            </div>
            <div class="form-group grid-cols-3">
              <NbTextInput
                :name="$t('carriersPage.trackstatusfunction')"
                id="track_status_function"
                :error="errors['track_status_function']"
                v-model="carrier.track_status_function"
              />
              <NbTextInput
                :name="$t('carriersPage.carrierCode')"
                id="carrier_code"
                :error="errors['carrier_code']"
                v-model="carrier.carrier_code"
              />
              <NbTextInput
                :name="$t('carriersPage.airportHub')"
                id="airport_code"
                :error="errors['airport_code']"
                v-model="carrier.airport_code"
                required
                :help-text="$t('carriersPage.airportHubHelpText')"
              />
            </div>
            <div class="form-group mt-2">
              <NbTextInput
                :name="$t('carriersPage.site')"
                id="site"
                :error="errors['site']"
                v-model="carrier.site"
              />
            </div>
            <div>
              <div class="d-flex align-items-center gap-2">
                <NbCheckbox
                  v-model="carrier.is_seller"
                  :name="$t('carriersPage.fields.isSeller')"
                  id="is_seller"
                  :help-text="$t('carriersPage.isSellerHelpText')"
                />
              </div>
              <div
                v-if="selectedCarrierId"
                class="d-flex flex-direction-columns justify-content-between"
              >
                <div class="ml-auto text-right">
                  <div class="input-label">
                    {{ $t("carriersPage.fields.creationDate") }}
                  </div>
                  <small class="d-block">{{ carrierCreatedAt }}</small>
                </div>
              </div>
            </div>
          </template>
        </NbCard>

        <NbCard
          :title="$t('carriersPage.carrierAddress')"
          :subTitle="$t('carriersPage.addCarrierOriginAddress')"
          id="endereco"
          class="my-4"
        >
          <template #body>
            <div class="form-group grid-cols-3">
              <NbTextInput
                :name="$t('carriersPage.address')"
                id="address"
                :error="errors['address']"
                v-model="carrier.address"
              />
              <NbTextInput
                :name="$t('carriersPage.addressnumber')"
                id="address_number"
                :error="errors['address_number']"
                v-model="carrier.address_number"
              />
              <NbTextInput
                :name="$t('carriersPage.addressComplement')"
                id="address_complement"
                :error="errors['address_complement']"
                v-model="carrier.address_complement"
              />
              <NbTextInput
                :name="$t('carriersPage.city')"
                id="city"
                :error="errors['city']"
                v-model="carrier.city"
              />
              <NbTextInput
                :name="$t('carriersPage.zipcode')"
                id="zip_code"
                :error="errors['zip_code']"
                v-model="carrier.zip_code"
              />
              <NbTextInput
                :name="$t('carriersPage.state')"
                id="state"
                :error="errors['state']"
                v-model="carrier.state"
              />
              <NbSelectInput
                :name="$t('carriersPage.country')"
                id="country"
                :error="errors['country']"
                v-model="carrier.country"
                :options="countriesOptions"
                required
              />
            </div>
          </template>
        </NbCard>
      </div>

      <ModalFooter class="d-flex justify-content-between mt-4">
        <ModalClose />
        <NbButton @click="openUpdateWarning()" :disabled="isLoading">
          {{ $t("save") }}
        </NbButton>
      </ModalFooter>
    </Modal>

    <Modal v-model="isModalUpdateWarningOpen">
      <ModalHeader>
        <ModalTitle>
          {{ $t("attention") }}
        </ModalTitle>
      </ModalHeader>
      <div>
        <div class="d-flex justify-content-between flex-wrap">
          <p class="text-bold">{{ $t("carriersPage.carrierIsSeller") }}</p>
          <p>{{ $t("carriersPage.sellerReminder") }}</p>
        </div>
      </div>
      <ModalFooter class="d-flex justify-content-between">
        <ModalClose>
          {{ $t("cancel") }}
        </ModalClose>
        <NbButton @click="editCarrier()">
          {{ $t("ok") }}
        </NbButton>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import CarrierService from "../../../services/CarrierService";
import moment from "moment";
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import NbCheckbox from "@/components/buttons/NbCheckbox.vue";
import NbDropFile from "@/components/input/drop-file/NbDropFile.vue";
import { jsonToFile } from "../../../utils/json-to-file";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import ModalCarrierView from "@/views/contracts/carriers/components/ModalCarrierView.vue";
import Modal from "@/components/modal/Modal.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import IsCheckedIcon from "../../../components/IsCheckedIcon.vue";

const carrierService = new CarrierService();

export default {
  name: "Carriers",
  components: {
    NbButton,
    NbIcon,
    DatatableRoot,
    NbTabs,
    NbBadge,
    NbCheckbox,
    NbDropFile,
    NbPageTitle,
    NbCard,
    NbTextInput,
    NbSelectInput,
    ModalCarrierView,
    Modal,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    IsCheckedIcon,
  },
  data: () => {
    return {
      modalImportCarrier: false,
      carrierDeliveryMethods: [],
      carrierUsers: [],
      currentCarrierPage: 1,
      profiles: ["nobordist", "seller", "carrier"],
      currentItemId: "",
      fields: [],
      errors: [],
      selectedSellerId: null,
      selectedCarrierId: null,
      isLoading: false,
      file: null,
      chosenFile: "",
      carrier: {
        name: "",
        address: "",
        address_number: "",
        address_complement: "",
        zip_code: "",
        state: "",
        city: "",
        phone: "",
        site: "",
        email: "",
        tax_number: "",
        legal_name: "",
        rfb_code: "",
        name_reference: "",
        slug: "",
        track_status_function: "",
        platform_id: "",
        carrier_code: "",
        country: "",
        airport_code: "",
        users: [],
        is_active: true,
        is_seller: false,
      },
      namespace: "carriers",
      carrierCreatedAt: "",
      platform_field: {
        key: "platform_name",
        label: "Platform",
        sortable: true,
      },

      total: 0,
      currentTab: "allCarriers",
      carrierSettingsDownloading: false,
      isModalCarrierView: false,
      isModalCarrierOpen: false,
      isModalUpdateWarningOpen: false,
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: "allCarriers",
          label: this.$t("carriersPage.carriers"),
          found: this.total || 0,
          current: this.currentTab,
        },
      ];
    },
    allSellers() {
      return this.$store.state.sellers.all_items;
    },
    allSellerOptions() {
      const sellerOptions = this.allSellers.map((item) => ({
        value: item.id,
        text: `(${item.id}) ${item.name}`,
      }));
      return sellerOptions;
    },
    countries() {
      return this.$store.state.countries.all_items;
    },
    countriesOptions() {
      let options = [{ text: this.$t("anyCounties"), value: "all" }];
      this.countries.map((country) => {
        options.push({ text: country.name, value: country.alpha2_code });
      });
      return options;
    },
    countriesOptionsFilter() {
      return this.countries.map((item) => ({
        value: item.alpha2_code,
        text: item.name,
      }));
    },
    isSuper() {
      return this.$store.state.user.is_super;
    },
    tableColumns() {
      const result = [
        { key: "id", label: "ID", thClass: ["small-content"] },
        {
          key: "name",
          label: this.$t("carriersPage.fields.name"),
        },
        {
          key: "email",
          label: this.$t("carriersPage.fields.email"),
        },
        {
          key: "carrier_code",
          label: this.$t("carriersPage.fields.carrierCode"),
        },
        {
          key: "track_status_function",
          label: this.$t("carriersPage.fields.trackFunction"),
        },
        {
          key: "rfb_code",
          label: this.$t("carriersPage.rfbCode"),
        },
        {
          key: "legal_name",
          label: this.$t("carriersPage.legalName"),
        },
        {
          key: "is_seller",
          label: this.$t("carriersPage.fields.isSeller"),
        },
        {
          key: "tax_number",
          label: this.$t("carriersPage.taxnumber"),
        },
        {
          key: "airport_code",
          label: this.$t("carriersPage.airportHub"),
        },
        {
          key: "address",
          label: this.$t("carriersPage.address"),
        },
        {
          key: "address_number",
          label: this.$t("carriersPage.addressnumber"),
        },
        {
          key: "address_complement",
          label: this.$t("carriersPage.addressComplement"),
        },
        {
          key: "zip_code",
          label: this.$t("carriersPage.zipcode"),
        },
        { key: "city", label: this.$t("carriersPage.fields.city") },
        {
          key: "state",
          label: this.$t("carriersPage.state"),
        },
        {
          key: "country",
          label: this.$t("carriersPage.fields.country"),
        },

        {
          key: "phone",
          label: this.$t("carriersPage.phone"),
        },
        {
          key: "site",
          label: this.$t("carriersPage.site"),
        },
        {
          key: "created_at",
          label: this.$t("carriersPage.fields.creationDate"),
          sortable: true,
        },
        {
          key: "edit",
          isCustomizable: false,
          label: this.$t("actions"),
          class: "right--20 right-sticky",
        },
      ];

      if (this.isSuper) {
        result.push(this.platform_field);
      }

      return result;
    },
    tableFilters() {
      return [
        {
          label: this.$t("carriersPage.fields.name"),
          inputs: [{ key: "name" }],
        },
        {
          label: this.$t("carriersPage.fields.email"),
          inputs: [{ key: "email" }],
        },

        {
          label: this.$t("carriersPage.rfbCode"),
          inputs: [{ key: "rfb_code" }],
        },
        {
          label: this.$t("carriersPage.legalName"),
          inputs: [{ key: "legal_name" }],
        },
        {
          label: this.$t("carriersPage.fields.carrierCode"),
          inputs: [{ key: "carrier_code" }],
        },
        {
          label: this.$t("carriersPage.fields.isSeller"),
          inputs: [{ key: "is_seller", type: "boolean" }],
        },
        {
          label: this.$t("carriersPage.airportHub"),
          inputs: [{ key: "airport_code" }],
        },
        {
          label: this.$t("carriersPage.fields.trackFunction"),
          inputs: [{ key: "track_status_function" }],
        },
        {
          label: this.$t("registrationDate"),
          inputs: [
            {
              key: "start_registration_date",
              type: "date",
            },
            {
              key: "end_registration_date",
              type: "date",
            },
          ],
        },
        {
          label: this.$t("carriersPage.address"),
          inputs: [{ key: "address" }],
        },
        {
          label: this.$t("carriersPage.addressnumber"),
          inputs: [{ key: "address_number" }],
        },
        {
          label: this.$t("carriersViewPage.addresscomplement"),
          inputs: [{ key: "address_complement" }],
        },
        {
          label: this.$t("carriersPage.zipcode"),
          inputs: [{ key: "zip_code" }],
        },

        {
          label: this.$t("carriersPage.fields.city"),
          inputs: [{ key: "city" }],
        },
        {
          label: this.$t("carriersPage.state"),
          inputs: [
            {
              key: "state",
            },
          ],
        },
        {
          label: this.$t("carriersPage.fields.country"),
          inputs: [
            {
              key: "country",
              type: "select",
              options: this.countriesOptionsFilter,
            },
          ],
        },
        {
          label: this.$t("carriersPage.phone"),
          inputs: [{ key: "phone" }],
        },
        {
          label: this.$t("carriersPage.site"),
          inputs: [{ key: "site" }],
        },
        {
          label: this.$t("carriersPage.taxnumber"),
          inputs: [{ key: "tax_number" }],
        },
      ];
    },
  },
  beforeMount() {
    this.$store.dispatch("sellers/fetchAllItems", "sellers");
    this.$store.dispatch("countries/fetchAllItems", "countries");
    this.carrier.platform_id = this.$store.state.user.platform_id;
  },
  mounted() {
    this.checkQuery();
    const carrierId = this.$route.query?.id;
    if (carrierId) {
      this.getCarrier(carrierId);
    }
  },
  methods: {
    resetQuery() {
      this.$urlSearchParams.delete("carrierId");
    },
    reloadCarriers() {
      this.$refs?.carriersRef?.getData();
    },
    checkQuery() {
      if (this.$route.query.carrierId) {
        this.loadModalData(this.$route.query.carrierId);
        return;
      }
    },
    loadModalData(id) {
      if (id) {
        this.currentItemId = id;

        if (!this.$route.query.carrierId) {
          this.$urlSearchParams.set("carrierId", id);
        }

        this.isModalCarrierView = true;
        return;
      }
    },
    fillSellerToCarrierData() {
      if (this.selectedSellerId) {
        const handleCarrier = { ...this.carrier };

        const seller = this.allSellers.find(
          (seller) => seller.id === this.selectedSellerId,
        );
        handleCarrier.address = seller.address;
        handleCarrier.address_number = seller.address_number;
        handleCarrier.address_complement = seller.address_complement;
        handleCarrier.city = seller.city;
        handleCarrier.zip_code = seller.zip_code;
        handleCarrier.state = seller.state;
        handleCarrier.country = seller.country;
        handleCarrier.name = seller.name;
        handleCarrier.email = seller.email;
        handleCarrier.phone = seller.phone;
        handleCarrier.site = seller.site;
        handleCarrier.tax_number = seller.tax_number;
        handleCarrier.rfb_code = seller.rfb_code;
        handleCarrier.legal_name = seller.legal_name;
        handleCarrier.users = seller.unarchived_users;
        handleCarrier.is_active = seller.is_active;
        handleCarrier.is_seller = true;

        this.carrier = handleCarrier;
      }
    },
    goToDeliveryMethodsPage() {
      this.$router.push("/contracts/delivery_methods");
    },
    setQueryParams(data = undefined) {
      this.$router.replace({
        query: {
          id: data,
        },
      });
    },
    async downloadCarrierSettings() {
      try {
        this.carrierSettingsDownloading = true;
        const { data } = await carrierService.exportCarrier(
          this.selectedCarrierId,
        );
        jsonToFile(data, `carrier-${this.selectedCarrierId}-settings.json`);
      } finally {
        this.carrierSettingsDownloading = false;
      }
    },
    sendFile() {
      this.file = new FormData();
      this.file.append("file", this.chosenFile);

      if (this.file) {
        this.errors = [];
        this.isLoading = true;
        carrierService
          .importCarrier(this.file)
          .then(() => {
            this.modalImportCarrier = false;
            this.file = null;
            this.chosenFile = "";
          })
          .catch((error) => {
            const errors = error?.response?.data?.messages;
            if (errors.length > 0) {
              this.errors = errors;
              this.$bvToast.toast(this.$t("errorMessages.genericError"), {
                variant: "danger",
              });
              return;
            }
          })
          .finally(() => {
            this.loadItems();
            this.isLoading = false;
          });
      }
    },
    openUpdateWarning() {
      if (this.selectedCarrierId) {
        const platform = localStorage.getItem("platform");
        if (platform === "total" && this.carrier.is_seller) {
          this.isModalUpdateWarningOpen = true;
          return;
        }
        this.editCarrier();
      } else {
        this.addCarrier();
      }
    },
    closeCarrierModal() {
      this.selectedCarrierId = null;
      this.resetQuery();
      this.setQueryParams();
    },
    showImportCarrierModal() {
      this.errors = [];
      this.carrierDeliveryMethods = [];
      this.carrierUsers = [];
      this.selectedCarrierId = null;
      this.setQueryParams();
      this.initializeNewCarrier();
      this.modalImportCarrier = true;
    },
    showCreatCarrierModal() {
      this.carrierDeliveryMethods = [];
      this.carrierUsers = [];
      this.selectedCarrierId = null;
      this.resetQuery();
      this.setQueryParams();
      this.initializeNewCarrier();
      this.isModalCarrierOpen = true;
    },
    showEditCarrierModal(data) {
      if (data.id != this.$route.query?.id) {
        this.setQueryParams(data.id);
      }
      this.selectedCarrierId = data?.id;

      this.carrier = {
        id: data?.id || "",
        name: data?.name || "",
        address: data?.address || "",
        address_number: data?.address || "",
        address_complement: data?.address_complement || "",
        zip_code: data?.zip_code || "",
        state: data?.state || "",
        city: data?.city || "",
        phone: data?.phone || "",
        site: data?.site || "",
        email: data?.email || "",
        tax_number: data?.tax_number || "",
        legal_name: data?.legal_name || "",
        rfb_code: data?.rfb_code || "",
        name_reference: data?.name_reference || "",
        slug: data?.slug || "",
        track_status_function: data?.track_status_function || "",
        platform_id: data?.platform_id || "",
        country: data?.country || "",
        airport_code: data?.airport_code || "",
        is_seller: data?.is_seller || false,
        is_active: data?.is_active || true,
        carrier_code: data?.carrier_code || "",
        delivery_methods: data?.delivery_methods || [],
        users: data?.unarchived_users || [],
      };

      if (data.created_at.includes("/")) {
        this.carrierCreatedAt = data.created_at;
        this.carrier.created_at = data.created_at;
      } else {
        this.carrier.created_at = data?.created_at
          ? moment(data.created_at).format("DD/MM/YYYY HH:mm:ss")
          : "";
        this.carrierCreatedAt = data?.created_at
          ? moment(data.created_at).format("DD/MM/YYYY HH:mm:ss")
          : "";
      }

      this.errors = [];
      this.isModalCarrierOpen = true;
    },
    addSelectOptionsToFilter(key, options) {
      this.filterOptions.find(
        (filterOption) => filterOption.key === key,
      ).options = options;
    },
    selectedRows(items) {
      this.selected = items;
    },
    editCarrier() {
      this.errors = [];
      this.isLoading = true;
      carrierService
        .updateCarrier(this.selectedCarrierId, this.carrier)
        .then(() => {
          this.$refs.carriersRef?.getData();
          this.$refs?.modalCarrierViewRef?.getData();

          this.isModalUpdateWarningOpen = false;
          this.isModalCarrierOpen = false;
        })
        .catch((error) => {
          const platform = localStorage.getItem("platform");

          if (
            platform === "total" &&
            this.carrier.is_seller &&
            this.selectedCarrierId
          ) {
            this.isModalUpdateWarningOpen = false;
          }
          const errors = error?.response?.data?.messages?.[0];
          if (errors) {
            this.errors = errors;
            return;
          }

          this.$bvToast.toast(this.$t("errorMessages.genericError"), {
            variant: "danger",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    addCarrier() {
      this.isLoading = true;
      carrierService
        .setCarrier(this.carrier)
        .then(() => {
          this.$refs.carriersRef?.getData();
          this.$helpers.closeAllModals();
        })
        .catch((error) => {
          const errors = error?.response?.data?.messages?.[0];
          if (errors) {
            this.errors = errors;
            return;
          }

          this.$bvToast.toast(this.$t("errorMessages.genericError"), {
            variant: "danger",
          });
        })
        .finally(() => (this.isLoading = false));
    },
    initializeNewCarrier() {
      this.carrier = {};
      this.carrier.name = "";
      this.carrier.address = "";
      this.carrier.address_number = "";
      this.carrier.address_complement = "";
      this.carrier.zip_code = "";
      this.carrier.state = "";
      this.carrier.city = "";
      this.carrier.phone = "";
      (this.carrier.site = ""), (this.carrier.email = "");
      this.carrier.tax_number = "";
      this.carrier.legal_name = "";
      this.carrier.rfb_code = "";
      this.carrier.name_reference = "";
      this.carrier.slug = "";
      this.carrier.track_status_function = "";
      this.carrier.platform_id = "";
      this.carrier.airport_code = "";
      this.carrierCreatedAt = "";
      this.carrier.users = [];
      this.errors = [];
      this.chosenFile = "";
    },
    moment: function (date) {
      let formated = moment(date);
      return formated;
    },
    getCarrier(id) {
      carrierService.getCarrier(id).then((response) => {
        this.showEditCarrierModal(response.data.data);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  .btn-actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    .btn-actions {
      flex-direction: column;
      margin-top: 0;
    }
  }
}

.form-group {
  display: grid;
  gap: 1rem;
  @media screen and (min-width: 768px) {
    &.grid-cols-2 {
      grid-template-columns: repeat(2, 1fr);
    }
    &.grid-cols-3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>
