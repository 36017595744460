<template>
  <div>
    <div>
      <ModalHeader>
        <div>
          <ModalTitle>
            {{ `${$t("contractsViewPage.title")} ${itemId}` }}
          </ModalTitle>
          <p class="body-4 mb-0">
            {{ $t("contractsViewPage.subTitle") }}
          </p>
        </div>

        <div v-if="contract?.logo_url" class="mr-4">
          <img :src="contract.logo_url" height="75px" width="auto" />
        </div>
      </ModalHeader>

      <div class="d-flex justify-content-between mb-5">
        <div></div>
        <NbCard
          v-show="!loading"
          id="actions-card"
          :title="$t('actions')"
          class="px-3"
        >
          <template #body>
            <div
              class="d-flex justify-content-between flex-wrap pt-3"
              style="max-width: 19.5rem"
            >
              <NbButton
                class="w-100 my-1 px-2 text-left text-14-20"
                icon="edit"
                @click="copyContract()"
                title="Delete Tracking Rule"
              >
                {{ $t("contractsViewPage.editContract") }}
              </NbButton>
              <NbButton
                class="w-100 my-1 px-2 text-left text-14-20"
                icon="copy"
                @click="showCloneContractModal()"
              >
                {{ $t("contractsViewPage.cloneContract") }}
              </NbButton>
              <NbButton
                class="w-100 my-1 px-2 text-left text-14-20"
                icon="download"
                @click="downloadContractSettings()"
                :disabled="contractSettingsDownloading"
                v-b-popover.hover.left
                :title="$t('contractsPage.downloadContractsSettingsHelpText')"
              >
                {{ $t("contractsViewPage.downloadContractSettings") }}
              </NbButton>
            </div>
          </template>
        </NbCard>
      </div>

      <div>
        <NbCard
          id="contract_namedefinitions_chard"
          :title="$t('contractsViewPage.nameDefinitions')"
          class="bg-gray-05"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap">
              <NbTextInput
                id="name"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('contractsViewPage.descriptionName')"
                v-model="contract.name"
                disabled
              />
              <NbTextInput
                id="slug"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('contractsViewPage.slug')"
                v-model="contract.slug"
                disabled
                :helpText="$t('contractsViewPage.slugHelpText')"
              />
              <NbTextInput
                id="incoterm"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('contractsViewPage.incoterm')"
                v-model="contract.incoterm"
                disabled
              />
            </div>
          </template>
        </NbCard>

        <NbCard
          id="contract_edit_card"
          :title="$t('contractsViewPage.termContract')"
          class="bg-gray-05 mt-3"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap">
              <NbTextInput
                id="created"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('contractsViewPage.created')"
                v-model="contract.created"
                disabled
              />
              <NbTextInput
                id="beginning_date"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-23 my-2"
                :name="$t('beginningDate')"
                v-model="contract.beginning_date_temp"
                disabled
              />
              <NbTextInput
                id="end_date"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-23 my-2"
                :name="$t('endDate')"
                v-model="contract.end_date_temp"
                disabled
              />
            </div>
          </template>
        </NbCard>

        <NbCard
          id="delivery_methods_card"
          :title="$t('contractsViewPage.deliveryMethods')"
          :subTitle="$t('contractsViewPage.deliveryMethodsSubtitle')"
          class="bg-gray-05 mt-3"
        >
          <template #body>
            <ContractDeliveryMethods
              :deliveryMethodNamesIds="deliveryMethodNamesIds"
            />
          </template>
        </NbCard>

        <NbCard
          id="weight_measure_card"
          :title="$t('contractsViewPage.weightMeasure')"
          class="bg-gray-05 mt-3"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap">
              <NbTextInput
                id="maximumLength"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32 my-2"
                :name="$t('contractsViewPage.maximumLength') + ' (cm)'"
                type="number"
                :decimals="2"
                append="cm"
                v-model="contract.max_length"
                disabled
              />
              <NbTextInput
                id="maximumHeight"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32 my-2"
                :name="$t('contractsViewPage.maximumHeight') + ' (cm)'"
                type="number"
                :decimals="2"
                append="cm"
                v-model="contract.max_height"
                disabled
              />
              <NbTextInput
                id="maximumWidth"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32 my-2"
                :name="$t('contractsViewPage.maximumWidth') + ' (cm)'"
                type="number"
                :decimals="2"
                append="cm"
                v-model="contract.max_width"
                disabled
              />
              <NbTextInput
                id="maximumWeight"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('contractsViewPage.maximumWeight') + ' (kg)'"
                type="number"
                :decimals="3"
                append="kg"
                v-model="contract.max_weight"
                disabled
              />
            </div>
          </template>
        </NbCard>

        <NbCard
          id="contract_download_card"
          :title="$t('contractsViewPage.priceTableDeliveryTime')"
          :subTitle="$t('contractsViewPage.priceDeliveryTimeSubtitle')"
          class="bg-gray-05 mt-3"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap">
              <div class="w-50 d-flex">
                <NbTextInput
                  id="priceTable"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  class="w-58 my-2 mr-1"
                  :name="$t('contractsViewPage.priceTable')"
                  v-model="price_table.name"
                  disabled
                />
                <NbButton
                  :disabled="price_table.name ? false : true"
                  class="mx-1"
                  size="sm"
                  variant="secondary"
                  icon="download"
                  style="margin-top: 2.2rem"
                  @click="printPricePdf()"
                >
                  pdf
                </NbButton>
                <NbButton
                  :disabled="price_table.name ? false : true"
                  class="mx-1"
                  size="sm"
                  variant="secondary"
                  icon="download"
                  style="margin-top: 2.2rem"
                  @click="printPriceCsv()"
                >
                  csv
                </NbButton>
              </div>

              <div class="w-50 d-flex">
                <NbTextInput
                  id="deliveryTime"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  class="w-58 my-2 mr-1 ml-3"
                  :name="$t('contractsViewPage.deliveryTime')"
                  v-model="delivery_time.name"
                  disabled
                />
                <NbButton
                  :disabled="delivery_time.name ? false : true"
                  class="mx-1"
                  size="sm"
                  variant="secondary"
                  icon="download"
                  style="margin-top: 2.2rem"
                  @click="printDeliveryPdf()"
                >
                  pdf
                </NbButton>
                <NbButton
                  :disabled="delivery_time.name ? false : true"
                  class="mx-1"
                  size="sm"
                  variant="secondary"
                  icon="download"
                  style="margin-top: 2.2rem"
                  @click="printDeliveryCsv()"
                >
                  csv
                </NbButton>
              </div>
              <NbTextInput
                id="maximumPrice"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-29 my-2"
                :name="$t('contractsViewPage.maximumPrice') + ' ($)'"
                type="money"
                :decimals="2"
                prepend="$"
                v-model="contract.price_limit"
                disabled
              />
            </div>
          </template>
        </NbCard>

        <NbCard
          v-if="contract?.additional_services?.length > 0"
          id="aditional_service_card"
          :title="$t('contractsViewPage.additionalServices')"
          :subTitle="$t('contractsViewPage.additionalServicesSubtitle')"
          class="bg-gray-05 mt-3"
        >
          <template #body>
            <div
              v-for="additionalService in contract.additional_services"
              :key="additionalService.id"
              class="d-flex justify-content-between flex-wrap"
            >
              <NbTextInput
                id="additionalService-name"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-24 my-2 mr-1"
                :name="$t('contractsViewPage.name')"
                v-model="additionalService.name"
                disabled
              />
              <NbTextInput
                id="additionalService-external_id"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-24 my-2 mr-1"
                :name="$t('contractsViewPage.externalID')"
                v-model="additionalService.external_id"
                disabled
              />
              <NbTextInput
                id="additionalService-trigger_option"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-24 my-2 mr-1"
                :name="$t('contractsViewPage.triggerOption')"
                v-model="additionalService.trigger_option"
                disabled
              />
              <NbTextInput
                id="additionalService-trigger_conditionm"
                variant="borderless-gray-10"
                :placeholder="
                  showCondictions(additionalService.trigger_condition)
                "
                class="w-24 my-2 mr-1"
                :name="$t('contractsViewPage.triggerCondition')"
                v-model="emptVal"
                disabled
              />
              <NbTextInput
                id="additionalService-primary_variable"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2 mr-1"
                :name="$t('contractsViewPage.primaryVariable')"
                v-model="additionalService.primary_variable"
                disabled
              />
              <NbTextInput
                id="additionalService-primary_value"
                variant="borderless-gray-10"
                :placeholder="standardizeValue(additionalService.primary_value)"
                :prepend="additionalService.primary_type === 'flat' ? '$' : ''"
                :append="additionalService.primary_type === 'flat' ? '' : '%'"
                class="w-48-5 my-2 mr-1"
                :name="$t('contractsViewPage.primaryValue')"
                v-model="emptVal"
                disabled
              />
              <NbTextInput
                id="additionalService-secondary_variable"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2 mr-1"
                :name="$t('contractsViewPage.secondaryVariable')"
                v-model="additionalService.secondary_variable"
                disabled
              />
              <NbTextInput
                id="additionalService-secondary_value"
                variant="borderless-gray-10"
                :placeholder="
                  standardizeValue(additionalService.secondary_value)
                "
                :prepend="
                  additionalService.secondary_type === 'flat' ? '$' : ''
                "
                :append="additionalService.secondary_type === 'flat' ? '' : '%'"
                class="w-48-5 my-2 mr-1"
                :name="$t('contractsViewPage.secondaryValue')"
                v-model="emptVal"
                disabled
              />
              <div>
                <div class="input-label mt-2">
                  {{ $t("contractsViewPage.hSCodes") }}:
                </div>
                <div class="d-flex">
                  <div
                    class="mx-1"
                    v-for="code in additionalService.hs_codes"
                    :key="code.id"
                  >
                    <NbBadge :text="code" />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </NbCard>

        <div class="d-flex justify-content-between flex-wrap">
          <NbCard
            id="destination_country_card"
            :title="$t('contractsPage.destinationCountry')"
            :subTitle="$t('contractsViewPage.destinationandOriginSubtitle')"
            class="bg-gray-05 mt-3 w-48-5"
          >
            <template #body>
              <div class="d-flex mt-3">
                <NbBadge
                  v-for="country in setCountryList(
                    contract.destination_country,
                  )"
                  :key="country.id"
                  class="w-fit mx-1"
                  :text="country"
                />
              </div>
            </template>
          </NbCard>
          <NbCard
            id="origin_country_card"
            :title="$t('contractsPage.originCountry')"
            :subTitle="$t('contractsViewPage.destinationandOriginSubtitle')"
            class="bg-gray-05 mt-3 w-48-5"
          >
            <template #body>
              <div class="d-flex mt-3">
                <NbBadge
                  v-for="country in setCountryList(contract.origin_country)"
                  :key="country.id"
                  class="w-fit mx-1"
                  :text="country"
                />
              </div>
            </template>
          </NbCard>

          <NbCard
            :title="$t('contractsViewPage.configurations')"
            class="bg-gray-05 mt-3 w-100"
            id="configs"
          >
            <template #body>
              <div class="grid grid-cols-2 gap-4">
                <NbTextInput
                  id="flow"
                  variant="borderless-gray-10"
                  placeholder="-"
                  :name="$t('flow')"
                  :value="
                    contract.is_old_flow
                      ? $t('contractsPage.old')
                      : $t('contractsPage.new')
                  "
                  disabled
                />
                <NbTextInput
                  id="mode"
                  variant="borderless-gray-10"
                  placeholder="-"
                  :name="$t('contractsPage.mode')"
                  :value="contract.airport_to_door ? 'A2D' : 'D2D'"
                  disabled
                />

                <div class="col-span-2 d-flex gap-4">
                  <div class="d-flex align-items-center gap-2">
                    <IsCheckedIcon :checked="contract.automatic_payment" />
                    <span class="input-label m-0">{{
                      $t("contractsPage.fields.automaticPayment")
                    }}</span>
                  </div>
                  <div class="d-flex align-items-center gap-2">
                    <IsCheckedIcon :checked="contract.is_made_by_seller" />
                    <span class="input-label m-0">{{
                      $t("contractsPage.fields.isMadeBySeller")
                    }}</span>
                  </div>
                </div>
              </div>
            </template>
          </NbCard>
        </div>
        <NbFooter
          :text="$t('components.footer.checkOurSupport')"
          link="https://nobordistinc.freshdesk.com/support/home"
        />
      </div>
      <ModalFooter>
        <ModalClose />
      </ModalFooter>
    </div>

    <!-- modals -->
    <Modal v-model="isModalContractCreateOpen" size="lg">
      <ModalContractCreate
        :contract.sync="newContract"
        :contractAdditionalServices.sync="loadedAdditionalServices"
        @reloadContracts="loadCurrentContract"
      />
    </Modal>
    <Modal
      v-model="isCloneContractModalOpen"
      id="modalCloneContract"
      modalConfig="modal-dialog-centered"
    >
      <ModalHeader>
        <ModalTitle>
          {{ $t("contractsViewPage.cloneContract") }}
        </ModalTitle>
      </ModalHeader>
      <div>
        <NbTextInput
          id="slugForNewContract"
          class="w-100 my-2"
          :name="$t('contractsViewPage.slugForNewContract')"
          v-model="newContract.slug"
        />
      </div>
      <ModalFooter class="d-flex justify-content-end gap-2">
        <ModalClose />
        <NbButton
          :disabled="newContract.slug !== contract.slug ? false : true"
          @click="cloneContract()"
        >
          {{ $t("confirm") }}
        </NbButton>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbCard from "@/components/cards/NbCard.vue";
import ContractDeliveryMethods from "@/views/contracts/contracts/components/ContractDeliveryMethods.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import ModalContractCreate from "@/views/contracts/contracts/components/ModalContractCreate.vue";
import { jsonToFile } from "@/utils/json-to-file";

import ContractService from "@/services/ContractService";
import DeliveryMethodsService from "@/services/DeliveryMethodsService";
import PriceTablesService from "@/services/PriceTablesService";
import DeliveryTimesService from "@/services/DeliveryTimesService";
import moment from "moment";
import IsCheckedIcon from "../../../../components/IsCheckedIcon.vue";
import Modal from "@/components/modal/Modal.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";

const contractService = new ContractService();
const deliveryMethodsService = new DeliveryMethodsService();
const priceTablesService = new PriceTablesService();
const deliveryTimesService = new DeliveryTimesService();

export default {
  components: {
    NbButton,
    NbTextInput,
    NbCard,
    ContractDeliveryMethods,
    NbBadge,
    NbFooter,
    ModalContractCreate,
    IsCheckedIcon,
    Modal,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
  },
  name: "ModalContractsView",
  props: {
    id: {
      type: String,
      default: "ModalContractsView",
    },
    itemId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      contractSettingsDownloading: false,
      emptVal: "",
      contract: {},
      deliveryMethods: [],
      deliveryMethodNamesIds: {
        first_mile_delivery_method_name: "",
        first_mile_delivery_method_id: "",
        freight_forwarder_delivery_method_name: "",
        freight_forwarder_delivery_method_id: "",
        custom_clearence_delivery_method_name: "",
        custom_clearence_delivery_method_id: "",
        last_mile_delivery_method_name: "",
        last_mile_delivery_method_id: "",
        reverse_delivery_method_name: "",
        reverse_delivery_method_id: "",
      },
      price_table: {},
      delivery_time: {},
      errors: {},
      newContract: {
        id: null,
        name: "",
        slug: "",
        freight_forwarder_delivery_method_id: "",
        custom_clearence_delivery_method_id: "",
        first_mile_delivery_method_id: "",
        last_mile_delivery_method_id: "",
        reverse_delivery_method_id: "",
        price_table_id: "",
        delivery_time_id: "",
        logo_url: "",
        destination_country: [],
        origin_country: [],
        taxing_type: "postal",
        incoterm: "BOTH",
        automatic_payment: null,
        max_weight: 0,
        max_width: 0,
        max_height: 0,
        max_length: 0,
        cubage_factor: 0,
        additional_services_attributes: [],
        is_old_flow: null,
        airport_to_door: null,
        generate_payment_link: null,
        dutypay_notification: {
          sms: false,
          whatsapp: false,
          email_seller: false,
          email_customer: false,
        },
        beginning_date: null,
        end_date: null,
        price_limit: 10000,
      },
      loadedAdditionalServices: [],
      trigger_options: [
        { value: 0, text: "Checkpoint" },
        { value: 1, text: "Action" },
        { value: 2, text: "Option" },
      ],
      variables: [
        { value: 1, text: "Volume" },
        { value: 2, text: "Item" },
        { value: 3, text: "Days" },
        { value: 4, text: "Kilo" },
      ],
      variable_types: [
        { value: 0, text: "Flat" },
        { value: 1, text: "Percent" },
      ],
      loading: true,
      isModalContractCreateOpen: false,
      isCloneContractModalOpen: false,
    };
  },
  methods: {
    showCloneContractModal() {
      this.copyContract();
      this.isCloneContractModalOpen = true;
    },
    async downloadContractSettings() {
      try {
        this.contractSettingsDownloading = true;
        const { data } = await contractService.exportContract(
          this.$route.query.contractId,
        );
        jsonToFile(
          data,
          `contract-${this.$route.query.contractId}-settings.json`,
        );
      } finally {
        this.contractSettingsDownloading = false;
      }
    },
    cloneContract() {
      this.newContract.id = null;
      this.isCloneContractModalOpen = false;
      this.isModalContractCreateOpen = true;
    },
    copyContract() {
      this.newContract = JSON.parse(JSON.stringify(this.contract));
      this.loadedAdditionalServices = this.newContract.additional_services;
      this.loadedAdditionalServices.forEach((service) => {
        service.trigger_option = this.trigger_options.find(
          (option) =>
            option.text.toUpperCase() ===
            service?.trigger_option?.toUpperCase(),
        ).value;

        //turn trigger_condiction into array of numbers
        if (service.trigger_option === 0) {
          service.trigger_condition = service.trigger_condition.map((item) =>
            Number(item),
          );
        }

        service.primary_variable = this.variables.find(
          (variable) =>
            variable.text.toUpperCase() ===
            service?.primary_variable?.toUpperCase(),
        )?.value;
        service.primary_type = this.variable_types.find(
          (type) =>
            type.text.toUpperCase() === service?.primary_type?.toUpperCase(),
        )?.value;
        service.secondary_variable = this.variables.find(
          (variable) =>
            variable.text.toUpperCase() ===
            service?.secondary_variable?.toUpperCase(),
        )?.value;
        service.secondary_type = this.variable_types.find(
          (type) =>
            type.text.toUpperCase() === service?.secondary_type?.toUpperCase(),
        )?.value;
      });

      if (!this.contract.dutypay_notification) {
        this.newContract.dutypay_notification = {
          sms: false,
          whatsapp: false,
          email_seller: false,
          email_customer: false,
        };
      }
    },
    setCountryList(countries) {
      if (countries && countries?.length === 1 && countries[0] === "all") {
        return [this.$t("contractsViewPage.anyCountry")];
      }
      return this.formatCountryList(countries);
    },
    formatCountryList(alphaCodes) {
      if (alphaCodes) {
        const countryNames = alphaCodes.map((alpha2Code) => {
          const country = this.countries.find(
            (country) => country.alpha2_code === alpha2Code,
          );
          return country ? country.name : alpha2Code;
        });
        return countryNames;
      }
      return [];
    },
    standardizeValue(val) {
      const finalVal = parseFloat(val).toFixed(2);
      if (finalVal === "NaN") {
        return "0.00";
      }
      return finalVal;
    },
    showCondictions(text) {
      if (Array.isArray(text)) {
        const finalResult = text.join(", ");
        return finalResult;
      }
      return text;
    },
    downloadPdf(id, ifPrice) {
      const printService = ifPrice ? priceTablesService : deliveryTimesService;
      printService.downloadPdf(id, this.contract.id).then((response) => {
        if (response.data.data && response.data.data.file) {
          let file = response.data.data.file;
          let pdf = `data:application/pdf;base64,${file}`;
          let download_link = document.createElement("a");
          download_link.href = pdf;
          download_link.download = ifPrice
            ? `price_table_${id}.pdf`
            : `delivery_time_${id}.pdf`;
          download_link.click();
        }
      });
    },
    printPricePdf() {
      this.downloadPdf(this.contract.price_table_id, true);
    },
    printDeliveryPdf() {
      this.downloadPdf(this.contract.delivery_time_id, false);
    },
    downloadCsv(id, name, ifPrice) {
      const printService = ifPrice ? priceTablesService : deliveryTimesService;
      const filename = ifPrice
        ? `price_table_${name}.csv`
        : `delivery_time_${name}.csv`;
      printService.downloadCsv(id).then((response) => {
        let universalBOM = "\uFEFF";
        let csv =
          "data:text/csv; charset=utf-8," +
          encodeURIComponent(universalBOM + response.data);
        let anchor = document.createElement("a");
        anchor.href = csv;
        anchor.target = "_blank";
        anchor.download = filename;
        anchor.click();
      });
    },
    printPriceCsv() {
      this.downloadCsv(
        this.contract.price_table_id,
        this.price_table.name,
        true,
      );
    },
    printDeliveryCsv() {
      this.downloadCsv(
        this.contract.delivery_time_id,
        this.delivery_time.name,
        false,
      );
    },
    loadCurrentContract() {
      this.loading = true;
      contractService.getOneContract(this.itemId).then((response) => {
        this.deliveryMethodNamesIds = {
          first_mile_delivery_method_name: "",
          first_mile_delivery_method_id: "",
          freight_forwarder_delivery_method_name: "",
          freight_forwarder_delivery_method_id: "",
          custom_clearence_delivery_method_name: "",
          custom_clearence_delivery_method_id: "",
          last_mile_delivery_method_name: "",
          last_mile_delivery_method_id: "",
          reverse_delivery_method_name: "",
          reverse_delivery_method_id: "",
        };
        let contract = response.data.data;
        if (this.$route.params.contract_params) {
          // contract = Object.assign({}, this.$route.params.contract_params.contract, this.$route.params.contract_params.contracts_params)
          contract = this.$route.params.contract_params.contract;
        }
        this.contract = contract;
        let date = this.contract.created_at;
        this.contract.created = this.formateDate(date);
        this.contract.beginning_date_temp = moment(
          this.contract.beginning_date,
        ).format("DD/MM/YYYY");
        this.contract.end_date_temp = moment(this.contract.end_date).format(
          "DD/MM/YYYY",
        );

        if (this.contract.first_mile_delivery_method_id) {
          deliveryMethodsService
            .getOneDeliveryMethod(this.contract.first_mile_delivery_method_id)
            .then((response) => {
              this.deliveryMethodNamesIds.first_mile_delivery_method_name =
                response.data.data.name;
              this.deliveryMethodNamesIds.first_mile_delivery_method_id =
                response.data.data.id;
            });
        }
        if (this.contract.freight_forwarder_delivery_method_id) {
          deliveryMethodsService
            .getOneDeliveryMethod(
              this.contract.freight_forwarder_delivery_method_id,
            )
            .then((response) => {
              this.deliveryMethodNamesIds.freight_forwarder_delivery_method_name =
                response.data.data.name;
              this.deliveryMethodNamesIds.freight_forwarder_delivery_method_id =
                response.data.data.id;
            });
        }
        if (this.contract.custom_clearence_delivery_method_id) {
          deliveryMethodsService
            .getOneDeliveryMethod(
              this.contract.custom_clearence_delivery_method_id,
            )
            .then((response) => {
              this.deliveryMethodNamesIds.custom_clearence_delivery_method_name =
                response.data.data.name;
              this.deliveryMethodNamesIds.custom_clearence_delivery_method_id =
                response.data.data.id;
            });
        }
        if (this.contract.last_mile_delivery_method_id) {
          deliveryMethodsService
            .getOneDeliveryMethod(this.contract.last_mile_delivery_method_id)
            .then((response) => {
              this.deliveryMethodNamesIds.last_mile_delivery_method_name =
                response.data.data.name;
              this.deliveryMethodNamesIds.last_mile_delivery_method_id =
                response.data.data.id;
            });
        }
        if (this.contract.reverse_delivery_method_id) {
          deliveryMethodsService
            .getOneDeliveryMethod(this.contract.reverse_delivery_method_id)
            .then((response) => {
              this.deliveryMethodNamesIds.reverse_delivery_method_name =
                response.data.data.name;
              this.deliveryMethodNamesIds.reverse_delivery_method_id =
                response.data.data.id;
            });
        }
        if (this.contract.price_table_id) {
          priceTablesService
            .getOnePriceTable(this.contract.price_table_id)
            .then((response) => {
              this.price_table = response.data.data;
            });
        }
        if (this.contract.delivery_time_id) {
          deliveryTimesService
            .getOne(this.contract.delivery_time_id)
            .then((response) => {
              this.delivery_time = response.data.data;
            });
        }
      });

      deliveryMethodsService.getDmNotMadeBySeller().then((response) => {
        this.deliveryMethods = response.data.data.elements;
        this.loading = false;
      });
    },
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date) {
      const data = new Date(date);
      const hours = new Date(date);
      const formatedDate =
        this.addZero(
          this.addZero(data.getDate()).toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear(),
        ) +
        "  " +
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedDate;
    },
    onlyDate(datetime) {
      if (!datetime) {
        return datetime;
      }
      const data = new Date(datetime);
      const date_time = this.addZero(
        this.addZero(data.getDate()).toString() +
          "-" +
          this.addZero(data.getMonth() + 1).toString() +
          "-" +
          data.getFullYear(),
      );
      return date_time.split(" ")[0];
    },
  },
  computed: {
    countries() {
      return this.$store.state.countries.all_items;
    },
  },
  created() {
    this.loadCurrentContract();
  },
};
</script>

<style scoped></style>
