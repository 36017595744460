<template>
  <div>
    <div>
      <ModalHeader>
        <ModalTitle>
          {{ `${$t("deliveryMethodsViewPage.title")} ${itemId}` }}
        </ModalTitle>
      </ModalHeader>
      <div class="d-flex justify-content-between mb-5">
        <Cannot :profiles="[Profile.CARRIER]">
          <NbCard
            id="actions-card"
            :title="$t('actions')"
            class="px-3"
            width="fit-content"
          >
            <template #body>
              <div class="d-flex justify-content-between flex-wrap pt-3">
                <div id="editdeliverymethod-btn" class="w-100">
                  <NbButton
                    :disabled="deliveryMethod.is_made_by_seller"
                    class="w-100 my-1 px-2 text-left text-14-20"
                    icon="edit"
                    @click="copyDeliveryMethod()"
                  >
                    {{ $t("deliveryMethodsViewPage.editDeliveryMethod") }}
                  </NbButton>
                  <b-popover
                    v-if="deliveryMethod.is_made_by_seller"
                    placement="top"
                    target="editdeliverymethod-btn"
                    triggers="hover focus"
                    variant="dark"
                  >
                    You can not edit delivery method created by a seller
                  </b-popover>
                </div>
              </div>
            </template>
          </NbCard>
        </Cannot>
      </div>
      <div>
        <NbCard
          id="checkpoint_chard"
          :title="$t('generalInformation')"
          class="bg-gray-05"
        >
          <template #body>
            <div>
              <div class="d-flex justify-content-between flex-wrap">
                <NbTextInput
                  id="name-deliverymethods-view"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  class="w-49-5 mb-2 mt-2"
                  :name="$t('deliveryMethodsViewPage.name')"
                  v-model="deliveryMethod.name"
                  disabled
                />
                <div
                  :class="`w-49-5 mb-2 mt-2 ${
                    deliveryMethod.carrierName ? 'link-1' : ''
                  } `"
                  @click="
                    pushTo(
                      `/users_management/carriers/${deliveryMethod.carrier_id}`,
                    )
                  "
                >
                  <NbTextInput
                    id="carrier_id-deliverymethods-view"
                    variant="borderless-gray-10"
                    :placeholder="$t('notFound')"
                    :name="$t('deliveryMethodsViewPage.carrier')"
                    v-model="deliveryMethod.carrierName"
                    disabled
                  />
                </div>
                <NbTextInput
                  id="service-deliverymethods-view"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  class="w-32-5 mb-2 mt-2"
                  :name="$t('deliveryMethodsViewPage.service')"
                  v-model="deliveryMethod.service"
                  disabled
                />
                <NbTextInput
                  v-if="['correios', 'skypostal'].includes(deliveryMethod.slug)"
                  id="nationalization-deliverymethods-view"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  class="w-32-5 mb-2 mt-2"
                  :name="$t('deliveryMethodsViewPage.nationalization')"
                  v-model="deliveryMethod.additional_settings.nationalization"
                  disabled
                />
                <NbTextInput
                  id="type-deliverymethods-view"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  class="w-32-5 mb-2 mt-2"
                  :name="$t('deliveryMethodsViewPage.type')"
                  v-model="deliveryMethod.type"
                  disabled
                />
                <div class="w-100 grid grid-cols-2 gap-4">
                  <NbTextInput
                    id="slug-deliverymethods-view"
                    variant="borderless-gray-10"
                    :placeholder="$t('notFound')"
                    class="w-100 mb-2 mt-2"
                    :name="$t('deliveryMethodsViewPage.cooperationSlug')"
                    v-model="deliveryMethod.slug"
                    disabled
                  />
                  <NbTextInput
                    id="created_by_seller"
                    variant="borderless-gray-10"
                    :placeholder="$t('notFound')"
                    class="w-100 mb-2 mt-2"
                    :name="$t('deliveryMethodsPage.fields.createdBySeller')"
                    v-model="deliveryMethod.is_made_by_seller"
                    disabled
                  />
                </div>
              </div>

              <div
                class="d-flex flex-direction-columns justify-content-between mt-2"
              >
                <div class="ml-auto text-right">
                  <div class="input-label">
                    {{ $t("carriersPage.fields.creationDate") }}
                  </div>
                  <small class="d-block">{{
                    deliveryMethod.created_at | dateTime
                  }}</small>
                </div>
              </div>
            </div>
          </template>
        </NbCard>
        <div class="d-flex justify-content-between mb-5">
          <NbCard id="dispatchCredentials_chard" class="bg-gray-05 w-100 mt-3">
            <template #body>
              <div class="border border-gray-20 my-1 py-2 px-3 h-fit">
                <span class="w-100 heading-1 mb-3">
                  {{ $t("deliveryMethodsPage.dispatchCredentials") }}
                </span>
                <div
                  v-for="(
                    dispatch_api_credentials, key
                  ) in deliveryMethod.dispatch_api_credentials"
                  :key="dispatch_api_credentials.id"
                  class="border-top border-gray-20 mt-2 py-1"
                >
                  <NbTextInput
                    :id="`${key + dispatch_api_credentials}
                    -dispatch-credentials`"
                    variant="borderless-gray-10"
                    :placeholder="$t('notFound')"
                    :name="key"
                    :textarea="true"
                    v-model="deliveryMethod.dispatch_api_credentials[key]"
                    disabled
                  />
                </div>
              </div>
            </template>
          </NbCard>
        </div>
        <NbCard
          v-if="deliveryMethod.contracts_using.length > 0"
          :title="$t('deliveryMethodsViewPage.usedContracts')"
          id="contractsUsing_chard"
          class="bg-gray-05 w-100 mt-3"
        >
          <template #body>
            <NbTablev2
              class="mt-5"
              tableOf="contractsBeingUsed"
              :hasParentItens="true"
              ref="contractsUsed"
              :hiddeTableOptions="true"
              height="fit-content"
              :allFields="allContractsFields"
              :fields="contractsFields"
              @reloadFields="contractsFields = $event"
              :startItems="deliveryMethod.contracts_using || []"
              :clickable="true"
              @clickedRow="handleRowClick"
            >
              <template #cell(used_in)="data">
                {{ contractsAttatched(data.item) }}
              </template>
              <template #cell(beginning_date)="data">
                {{ formatDate(data.item.beginning_date) }}
              </template>
              <template #cell(end_date)="data">
                {{ formatDate(data.item.end_date) }}
              </template>
            </NbTablev2>
          </template>
        </NbCard>

        <NbFooter
          :text="$t('components.footer.checkOurSupport')"
          link="https://nobordistinc.freshdesk.com/support/home"
        />
      </div>
      <ModalFooter class="d-flex justify-content-end">
        <ModalClose />
      </ModalFooter>
    </div>
    <Modal v-model="isModalDeliveryMethodsEditOpen">
      <ModalDeliveryMethodsCreate
        :deliveryMethod.sync="newDeliveryMethod"
        @reloadDeliveryMethods="getData()"
      />
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import ModalDeliveryMethodsCreate from "@/views/contracts/delivery_methods/components/ModalDeliveryMethodsCreate.vue";

import DeliveryMethodsService from "@/services/DeliveryMethodsService";
import CarrierService from "@/services/CarrierService";
import Cannot from "../../../../components/Cannot.vue";
import { Profile } from "../../../../constants";
import moment from "moment";
import NbTablev2 from "@/components/tables/NbTablev2.vue";

const deliveryMethodsService = new DeliveryMethodsService();
const carrierService = new CarrierService();

export default {
  components: {
    NbButton,
    NbTextInput,
    NbCard,
    NbFooter,
    ModalDeliveryMethodsCreate,
    Cannot,
    NbTablev2,
    Modal,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
  },
  name: "ModalDeliveryMethodView",
  filters: {
    dateTime(value) {
      return value && moment(value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  props: {
    id: {
      type: String,
      default: "ModalDeliveryMethodView",
    },
    itemId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      Profile,
      deliveryMethod: {
        carrierName: "",
        contracts_using: [],
      },
      newDeliveryMethod: {},
      currentItemId: "",
      contractsFields: [],
      isModalDeliveryMethodsEditOpen: false,
    };
  },
  computed: {
    allContractsFields() {
      return [
        { key: "name", label: "Name", sortable: false },
        { key: "id", label: "ID", sortable: false },
        { key: "beginning_date", label: "Start Date", sortable: false },
        { key: "end_date", label: "End Date", sortable: false },
        { key: "used_in", label: "Used In", sortable: false },
      ];
    },
  },
  methods: {
    contractsAttatched(contactsData) {
      const contractNames = [];

      if (
        contactsData.first_mile_delivery_method_id == this.deliveryMethod?.id
      ) {
        contractNames.push("FirstMile");
      }

      if (
        contactsData.last_mile_delivery_method_id == this.deliveryMethod?.id
      ) {
        contractNames.push("LastMile");
      }

      if (
        contactsData.freight_forwarder_delivery_method_id ==
        this.deliveryMethod?.id
      ) {
        contractNames.push("FreightForwarder");
      }

      if (
        contactsData.custom_clearence_delivery_method_id ==
        this.deliveryMethod?.id
      ) {
        contractNames.push("CustomClearence");
      }

      if (contactsData.reverse_delivery_method_id == this.deliveryMethod?.id) {
        contractNames.push("Reverse");
      }

      return contractNames.join(" ");
    },
    handleRowClick(row) {
      this.$helpers.closeModal(this.id);
      this.$router.push(`/contracts/contracts?contractId=${row.id}`);
    },
    pushTo(path) {
      this.$helpers.closeModal(this.id);
      this.$router.push({
        path: path,
      });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    copyDeliveryMethod() {
      this.newDeliveryMethod = { ...this.deliveryMethod };
      this.isModalDeliveryMethodsEditOpen = true;
    },
    getData() {
      deliveryMethodsService
        .getOneDeliveryMethod(this.itemId)
        .then((response) => {
          this.deliveryMethod = response.data.data;
          this.deliveryMethod.slug = this.deliveryMethod?.slug?.title;
          carrierService
            .getOneCarrier(this.deliveryMethod.carrier_id)
            .then((response) => {
              this.deliveryMethod.carrierName = response.data.data.name;
            });
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped></style>
