import { render, staticRenderFns } from "./Carriers.vue?vue&type=template&id=3743a748&scoped=true"
import script from "./Carriers.vue?vue&type=script&lang=js"
export * from "./Carriers.vue?vue&type=script&lang=js"
import style0 from "./Carriers.vue?vue&type=style&index=0&id=3743a748&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3743a748",
  null
  
)

export default component.exports