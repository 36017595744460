<template>
  <div>
    <ModalHeader>
      <div class="d-flex flex-column">
        <ModalTitle>
          {{
            `${$t("trackingRulesViewPage.title")} ${trackingRule.name ?? "---"}`
          }}
        </ModalTitle>
        <NbBadge v-if="trackingMode" :text="trackingMode" class="d-flex" />
      </div>
    </ModalHeader>
    <div class="d-flex justify-content-between mb-5">
      <div></div>
      <NbCard
        id="actions-card"
        :title="$t('actions')"
        class="px-3"
        width="15rem"
      >
        <template #body>
          <div class="d-flex justify-content-between flex-wrap pt-3">
            <NbButton
              class="w-100 my-1 px-2 text-left text-14-20"
              variant="secondary"
              @click="isModalDeleteOpen = true"
              title="Delete Tracking Rule"
            >
              {{ $t("trackingRulesViewPage.deleteTrackingRule") }}
            </NbButton>
            <NbButton
              class="w-100 my-1 px-2 text-left text-14-20"
              @click="copyTrackingRule()"
            >
              {{ $t("trackingRulesViewPage.editTrackingRule") }}
            </NbButton>
          </div>
        </template>
      </NbCard>
    </div>
    <div class="grid gap-4">
      <NbCard
        id="checkpoint_chard"
        :title="$t('generalInformation')"
        class="bg-gray-05"
      >
        <template #body>
          <div class="d-flex justify-content-between flex-wrap">
            <div
              :class="['w-48-5 my-2', { 'link-1': !!trackingRule.seller_id }]"
              @click="
                trackingRule.seller_id &&
                  pushTo(
                    `/users_management/sellers?id=${trackingRule.seller_id}`,
                  )
              "
            >
              <NbTextInput
                id="seller_name-tracking-rule-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                :name="$t('trackingRulesViewPage.seller')"
                v-model="seller_name"
                disabled
              />
            </div>
            <div
              :class="['w-48-5 my-2', { 'link-1': !!trackingRule.contract_id }]"
              @click="
                trackingRule.contract_id &&
                  pushTo(
                    `/contracts/contracts?contractId=${trackingRule.contract_id}`,
                  )
              "
            >
              <NbTextInput
                id="contract_name-tracking-rule-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                :name="$t('trackingRulesViewPage.contract')"
                v-model="contract_name"
                disabled
              />
            </div>
            <div
              class="w-100 my-2 link-1"
              @click="
                trackingRule?.start_checkpoint_id &&
                  pushTo(
                    `/checkpoints/checkpoints?nobordistCheckpointId=${trackingRule.start_checkpoint_id}`,
                  )
              "
            >
              <NbTextInput
                id="start_checkpoint_name-tracking-rule-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                :name="$t('trackingRulesViewPage.startCheckpoint')"
                v-model="start_checkpoint_name"
                disabled
              />
            </div>

            <div class="w-100 d-flex justify-content-end">
              <div>
                <label for="" class="input-label">{{
                  $t("registrationDate")
                }}</label>
                <span class="d-block">
                  {{ trackingRule.created_at | datetime }}
                </span>
              </div>
            </div>

            <div
              class="w-48-5 my-2 link-1"
              @click="loadModalData(trackingRule.end_checkpoint_id)"
            >
              <NbTextInput
                v-if="
                  newTrackingRule.tracking_mode == 'time_between_checkpoints'
                "
                id="end_checkpoint_id-tracking-rule-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                :name="$t('trackingRulesViewPage.endCheckpoint')"
                v-model="end_checkpoint_name"
                disabled
              />
            </div>

            <NbTextInput
              v-if="newTrackingRule.tracking_mode == 'time_between_checkpoints'"
              id="days-tracking-rule-view"
              variant="borderless-gray-10"
              :placeholder="$t('notFound')"
              class="w-48-5 my-2"
              :name="$t('trackingRulesViewPage.daysLimit')"
              v-model="trackingRule.days"
              disabled
            />
          </div>
        </template>
      </NbCard>

      <NbCard
        title="Configured actions"
        class="bg-gray-05"
        id="configured-actions"
      >
        <template #body>
          <Accordion>
            <AccordionItem>
              <AccordionTrigger>
                {{ $t("trackingRulesViewPage.seller") }}
              </AccordionTrigger>
              <AccordionContent>
                <div
                  v-if="trackingRule?.configuration?.notify_seller"
                  class="grid gap-4 mb-4"
                >
                  <NbTextInput
                    id="seller_mail_title-tracking-rule-view"
                    variant="borderless-gray-10"
                    :placeholder="$t('notFound')"
                    :name="$t('trackingRulesViewPage.sellerMailTitle')"
                    v-model="trackingRule.configuration.seller_mail_title"
                    disabled
                  />

                  <NbTextInput
                    id="seller_mail_body-tracking-rule-view"
                    variant="borderless-gray-10"
                    :textarea="true"
                    :placeholder="$t('notFound')"
                    :name="$t('trackingRulesViewPage.sellerMailBody')"
                    v-model="trackingRule.configuration.seller_mail_body"
                    disabled
                  />
                </div>

                <p v-else class="body-04">
                  {{ $t("trackingRulesViewPage.noActionConfigured") }}
                </p>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem>
              <AccordionTrigger>
                {{ $t("trackingRulesViewPage.customer") }}
              </AccordionTrigger>
              <AccordionContent>
                <div
                  v-if="trackingRule?.configuration?.notify_customer"
                  class="grid gap-4 mb-4"
                >
                  <NbTextInput
                    id="customer_mail_title-tracking-rule-view"
                    variant="borderless-gray-10"
                    :placeholder="$t('notFound')"
                    :name="$t('trackingRulesPage.titleRules')"
                    v-model="trackingRule.configuration.customer_mail_title"
                    disabled
                  />
                  <NbTextInput
                    id="customer_mail_body-tracking-rule-view"
                    variant="borderless-gray-10"
                    :textarea="true"
                    :placeholder="$t('notFound')"
                    :name="$t('body')"
                    v-model="trackingRule.configuration.customer_mail_body"
                    disabled
                  />
                </div>

                <p v-else class="body-04">
                  {{ $t("trackingRulesViewPage.noActionConfigured") }}
                </p>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem>
              <AccordionTrigger>
                {{ $t("trackingRulesPage.sellerInternalNotification") }}
              </AccordionTrigger>
              <AccordionContent>
                <div
                  v-if="trackingRule?.configuration?.seller_notification"
                  class="grid gap-4 mb-4"
                >
                  <NbTextInput
                    id="seller_notification_title-tracking-rule-view"
                    variant="borderless-gray-10"
                    :placeholder="$t('notFound')"
                    :name="$t('trackingRulesPage.titleRules')"
                    v-model="
                      trackingRule.configuration.seller_notification_title
                    "
                    disabled
                  />
                  <NbTextInput
                    id="seller_notification_body-tracking-rule-view"
                    variant="borderless-gray-10"
                    :textarea="true"
                    :placeholder="$t('notFound')"
                    :name="$t('body')"
                    v-model="
                      trackingRule.configuration.seller_notification_body
                    "
                    disabled
                  />
                </div>

                <p v-else class="body-04">
                  {{ $t("trackingRulesViewPage.noActionConfigured") }}
                </p>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem>
              <AccordionTrigger>
                {{ $t("trackingRulesViewPage.nobordist") }}
              </AccordionTrigger>
              <AccordionContent>
                <div
                  v-if="trackingRule.configuration.notify_nobordist"
                  class="grid gap-4 mb-4"
                >
                  <NbTextInput
                    id="nobordist_mail_title-tracking-rule-view"
                    variant="borderless-gray-10"
                    :placeholder="$t('notFound')"
                    :name="$t('trackingRulesPage.titleRules')"
                    v-model="trackingRule.configuration.nobordist_mail_title"
                    disabled
                  />
                  <NbTextInput
                    id="destination-tracking-rule-view"
                    variant="borderless-gray-10"
                    :placeholder="$t('notFound')"
                    :name="$t('address')"
                    v-model="trackingRule.destination"
                    disabled
                  />
                  <NbTextInput
                    id="nobordist_mail_body-tracking-rule-viewv"
                    variant="borderless-gray-10"
                    :textarea="true"
                    :placeholder="$t('notFound')"
                    :name="$t('body')"
                    v-model="trackingRule.configuration.nobordist_mail_body"
                    disabled
                  />
                </div>
                <p class="body-04" v-else>
                  {{ $t("trackingRulesViewPage.noActionConfigured") }}
                </p>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem>
              <AccordionTrigger>
                {{ $t("trackingRulesViewPage.webhook") }}
              </AccordionTrigger>
              <AccordionContent>
                <div
                  v-if="trackingRule.configuration.notify_webhook"
                  class="grid grid-cols-2 gap-4"
                >
                  <NbTextInput
                    id="method-tracking-rule-view"
                    variant="borderless-gray-10"
                    :placeholder="$t('notFound')"
                    :name="$t('trackingRulesViewPage.method')"
                    v-model="trackingRule.configuration.webhook.method"
                    disabled
                  />
                  <NbTextInput
                    id="endpoint-tracking-rule-view"
                    variant="borderless-gray-10"
                    :placeholder="$t('notFound')"
                    :name="$t('trackingRulesViewPage.endpoint')"
                    v-model="trackingRule.configuration.webhook.endpoint"
                    disabled
                  />
                  <div class="col-span-2">
                    <label for="headers" class="input-label">
                      {{ $t("trackingRulesViewPage.headers") }}
                    </label>
                    <pre class="bg-gray-10 px-3 pt-2 pb-0">
                    <code>
                      {{ trackingRule.configuration.webhook.headers }}
                    </code>
                  </pre>
                  </div>
                  <NbTextInput
                    id="body-tracking-rule-view"
                    variant="borderless-gray-10"
                    :textarea="true"
                    :placeholder="$t('notFound')"
                    class="col-span-2"
                    :name="$t('trackingRulesViewPage.body')"
                    v-model="trackingRule.configuration.webhook.body"
                    disabled
                  />
                </div>
                <p class="body-04" v-else>
                  {{ $t("trackingRulesViewPage.noActionConfigured") }}
                </p>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem>
              <AccordionTrigger>
                {{ $t("trackingRulesPage.log") }}
              </AccordionTrigger>
              <AccordionContent>
                <div class="d-flex align-items-center gap-1 mb-2">
                  <NbIcon
                    :icon="trackingRule.configuration.log ? 'check' : 'x'"
                    :class="
                      trackingRule.configuration.log
                        ? 'text-success'
                        : 'text-danger'
                    "
                    :size="20"
                  />
                  <span>
                    {{
                      trackingRule.configuration.log
                        ? $t("enabled")
                        : $t("disabled")
                    }}
                  </span>
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </template>
      </NbCard>

      <NbFooter
        :text="$t('components.footer.checkOurSupport')"
        link="https://nobordistinc.freshdesk.com/support/home"
      />
    </div>
    <ModalFooter>
      <ModalClose />
    </ModalFooter>

    <Modal v-model="isModalDeleteOpen">
      <ModalHeader>
        <div class="d-flex flex-column">
          <ModalTitle>
            {{ $t("trackingRulesViewPage.deleteTrackingRule") }}
          </ModalTitle>
          <p class="body-04">
            {{ $t("trackingRulesViewPage.confirmDeleteRule") }}
          </p>
        </div>
      </ModalHeader>
      <ModalFooter>
        <div class="d-flex justify-content-end gap-2">
          <ModalClose />
          <NbButton @click="deleteTrackingRule()">
            {{ $t("delete") }}
          </NbButton>
        </div>
      </ModalFooter>
    </Modal>

    <Modal
      v-model="isModalNobordistCheckpointsViewOpen"
      size="lg"
      @close="resetQuery"
    >
      <ModalNobordistCheckpointsView :itemId="currentItemId" />
    </Modal>

    <Modal v-model="isModalUpdateOpen" size="lg">
      <ModalTrackingRuleCreate
        :trackingRule="newTrackingRule"
        @updateTrackingRule="
          getData();
          $emit('reloadTrackingRules');
        "
      />
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import ModalNobordistCheckpointsView from "@/views/checkpoints/nobordist_checkpoints/components/ModalNobordistCheckpointsView.vue";
import ModalTrackingRuleCreate from "@/views/logs/tracking_rules/components/ModalTrackingRuleCreate.vue";
import TrackingRuleService from "@/services/TrackingRuleService";
import CheckpointService from "@/services/CheckpointService";
import SellerService from "@/services/SellerService";
import ContractService from "@/services/ContractService";
import Accordion from "@/components/accordion/Accordion.vue";
import AccordionContent from "@/components/accordion/AccordionContent.vue";
import AccordionTrigger from "@/components/accordion/AccordionTrigger.vue";
import AccordionItem from "@/components/accordion/AccordionItem.vue";
import NbIcon from "@/components/icons/NbIcon.vue";

const trackingRuleService = new TrackingRuleService();
const checkpointService = new CheckpointService();
const sellerService = new SellerService();
const contractService = new ContractService();

export default {
  components: {
    Modal,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
    NbTextInput,
    NbCard,
    NbFooter,
    ModalNobordistCheckpointsView,
    ModalTrackingRuleCreate,
    NbBadge,
    Accordion,
    AccordionContent,
    AccordionTrigger,
    AccordionItem,
    NbIcon,
  },
  name: "ModalTrackingRuleView",
  props: {
    id: {
      type: String,
      default: "ModalTrackingRuleView",
    },
    itemId: {
      type: [Number, String],
      required: true,
    },
  },
  inject: ["context"],
  data() {
    return {
      trackingRule: {},
      checkpoints: [],
      sellers: [],
      contracts: [],
      seller_name: this.$t("any"),
      contract_name: this.$t("any"),
      start_checkpoint_name: "",
      end_checkpoint_name: "",
      hkey: "",
      hvalue: "",
      newTrackingRule: {
        configuration: {
          log: false,
        },
      },
      currentItemId: "",
      isModalDeleteOpen: false,
      isModalUpdateOpen: false,
      isModalNobordistCheckpointsViewOpen: false,
    };
  },
  computed: {
    trackingMode() {
      return (
        this.trackingRule.tracking_mode
          ?.split("_")
          ?.map((item) =>
            item.charAt(0).toUpperCase().concat(item.substring(1)),
          )
          ?.join(" ") || ""
      );
    },
  },
  methods: {
    resetQuery() {
      this.$urlSearchParams.delete("nobordistCheckpointId");
    },
    deleteTrackingRule() {
      trackingRuleService.deleteTrackingRule(this.itemId).then(() => {
        this.$emit("reloadTrackingRules");
        this.isModalDeleteOpen = false;
        this.context?.close();
      });
    },
    loadModalData(id) {
      if (id) {
        this.currentItemId = id;

        if (!this.$route.query.nobordistCheckpointId) {
          this.$router.push({ query: { nobordistCheckpointId: id } });
        }
        this.isModalNobordistCheckpointsViewOpen = true;
        return;
      }
    },
    pushTo(path) {
      this.$router.push({
        path: path,
      });
    },
    replaceTrackingRules() {
      this.trackingRule = Object.assign({}, this.newTrackingRule);
    },
    copyTrackingRule() {
      this.errors = [];
      const data = Object.assign({}, this.trackingRule);
      if (typeof data?.configuration?.webhook?.body !== "string") {
        data.configuration.webhook.body = JSON.stringify(
          data.configuration.webhook.body,
        );
      }

      this.newTrackingRule = data;
      this.isModalUpdateOpen = true;
    },
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date) {
      const data = new Date(date);
      const formatedDate =
        data.getFullYear() +
        "-" +
        this.addZero(data.getMonth() + 1).toString() +
        "-" +
        this.addZero(data.getDate().toString());
      return formatedDate;
    },
    getData() {
      this.trackingRule = {};
      trackingRuleService.getTrackingRule(this.itemId).then((response) => {
        this.trackingRule = response.data.data;

        let date = this.trackingRule.created_at;
        this.trackingRule.created = this.formateDate(date);

        if (this.trackingRule.seller_id) {
          sellerService
            .getOneSeller(this.trackingRule.seller_id)
            .then((response) => {
              this.seller_name = response.data.data.name;
            });
        }
        if (this.trackingRule.contract_id) {
          contractService
            .getOneContract(this.trackingRule.contract_id)
            .then((response) => {
              this.contract_name = response.data.data.name;
            });
        }
        if (this.trackingRule.start_checkpoint_id) {
          checkpointService
            .getCheckpoint(this.trackingRule.start_checkpoint_id)
            .then((response) => {
              this.start_checkpoint_name = response.data.data.name;
            });
        }
        if (this.trackingRule.end_checkpoint_id) {
          checkpointService
            .getCheckpoint(this.trackingRule.end_checkpoint_id)
            .then((response) => {
              this.end_checkpoint_name = response.data.data.name;
            })
            .catch(() => {
              this.end_checkpoint_name = null;
            });
        }
      });
      //this is for edit tracking rules could call it when edit?
      /* checkpointService.getAllCheckpoints().then((response) => {
          this.checkpoints = response.data.data.elements;
        });
        sellerService.getAllSellers().then((response) => {
          this.sellers = response.data.data.elements;
        });
        contractService.getAllContracts().then((response) => {
          this.contracts = response.data.data.elements;
        }); */
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped></style>
